<template>
    <CModal
        :show="show"
        :centered="true"
        :title="title"
        :size="size ? size : 'lg'"
        color="dark"
        @update:show="updateModalShow"
    >
        <div class="flexCenter" v-if="loading">
            <CSpinner  color="primary"/>
        </div>
        <component v-else :is="customComponent" ref="customComponent" :params="data" :additional="additionalEvent" ></component>

        <template #footer>
            <CButton style="margin-right: .3rem;" size="sm"  v-for="b in additionalButtons"  @click="modalHandle('additional', b)" :key="b.name" :color="b.color"> {{ b.name }} </CButton>
            <CButton  @click="modalHandle('cancel')" color="dark"> Kapat </CButton>
        </template>

    </CModal>
</template>

<script>
    export default{
        name: 'Modal',
        props:{
            show: Boolean,
            title: String,
            openModalEvent: Function,
            module: String,
            form: String,
            data: Object,
            loading: Boolean,
            additionalButtons: Array,
            additionalEvent: Function,
            size: String
        },
        data: function(){
            return{
                openModal: false,
            }
        },
        computed:{
            customComponent: function(){
                if(this.module && this.form)
                    return () => import(`../${this.module}/modal/${this.form}` )
            }
        },
        methods:{
            updateModalShow: function(){
                this.openModalEvent();
            },
            confirmModalEvent(title, desc){
                this.openModal = !this.openModal
                this.confirmTitle = title
                this.desc = desc
            },
            modalHandle(data, additional){
                if(data == 'additional'){
                    this.additionalEvent && this.additionalEvent(this.$refs.customComponent.data, additional.action);
                }else{
                    this.openModalEvent();
                }
            }
        }
    }

</script>
<style scoped>
 .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
 }
</style>