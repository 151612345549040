<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-7">Sipariş Kontrol Penceresi</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div v-show="shippingLoading">
            <p>Kargo bilgilendirme işlemi devam ediyor lütfen sayfayı kapatmayınız...</p>
          </div>
          <div class="order">
            <CCard style=" box-shadow: 0px 0px 1px 1px lightgray;">
              <CCardHeader>
                <strong>Sipariş No</strong>
              </CCardHeader>
                <CCardBody style="padding: 1rem ;">
                  <CRow>
                    <CCol>
                      <CInput
                          type="input"
                          horizontal
                          placeholder="Sipariş no"
                          id="orderId"
                          v-model="orderId"
                          v-on:keyup.enter="listItem(orderId)"
                        />
                        <CButton type="submit" color="success" @click="listItem(orderId)">Sorgula</CButton>
                    </CCol>
                  </CRow>
                        
                </CCardBody>
            </CCard>
            <transition name="fade">
              <CCard v-if="true" style="height: 90%; box-shadow: 0px 0px 1px 1px lightgray;">
                <CCardHeader>
                  <strong>Sipariş İçerik</strong>
                </CCardHeader>
                  <CCardBody style="padding: 1rem ;">
                        <CRow>
                          <CCol col="9">
                            <small><strong style="color: dodgerblue;">{{ checkedMessage }}</strong></small>
                            <small><strong style="color: green;">{{ allCheckedMessage }}</strong></small>
                            <CInput
                              style="margin-top: 10px"
                              horizontal
                              id="barcodeNumber"
                              placeholder="Barkod numarası"
                              v-model="barcodeNo"
                              v-on:keyup.enter="approveOrder(barcodeNo)"
                            />
                            <small><strong style="color: dodgerblue;">{{ giftCardMessage }}</strong></small><br/>
                            <small><strong style="color: orangered;">{{ clothBagMessage }}</strong></small>

                            <CInput
                              horizontal
                              placeholder="Hediye kartı barkod numarası"
                              v-model="giftCardNo"
                              id="giftCardNumber"
                              v-on:keyup.enter="approveGiftCard(giftCardNo)"
                            />
                          </CCol>
                          <CCol>
                            <img v-if="showEttnImg" style="width: 60px; height: 60px"
                              src="../../assets/img/thereisno-ettn-icon.png"><br/>
                            <img v-if="showStreetImg" style="width: 60px; height: 60px"
                                  src="../../assets/img/street-icon.png"><br/>
                            <img v-if="showWarningImg" style="width: 60px; height: 60px; cursor: pointer" src="../../assets/img/warning-icon.png"
                                @click="openModalEvent('list', 'orderCheckBlackList', null, 'Black List Kayıtları')" 
                            ><br/>
                          </CCol>
                        </CRow>
                  </CCardBody>
              </CCard>
            </transition>
          </div>
          
          <CDataTable
            :items="orderCheckList"
            :items-per-page="10"
            :fields="fields"
            :loading="loading"
            clickable-rows
            @row-clicked="rowClicked"
            @keyup.enter.native="listItem(orderId)"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CButton
                  color="info"
                  square
                  size="sm"
                  @click="approveOrder(item.barcode)"
                >
                  Onayla
                </CButton>
              </td>
            </template>
            <template #url="{item}">
              <td><img :src="item.url"></td>
            </template>
            <template #description="{item}">
              <td>
                <span v-html="item.description"></span>
              </td>
            </template>
            <template #check="{item}">
              <td>
                {{ item.check ? item.check : 0 }}
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <CModal
        :show="shippingLoading"
        :centered="true"
        title="Kargo Bilgilendirmesi"
        size="sm"
    >
        <div class="flexCenter">
            <p> Kargo bilgilendirme işlemi devam ediyor lütfen pencereyi kapatmayınız... </p> 
            <CSpinner  color="primary"/>
        </div>
      
        <template #footer>
            <span>
            </span>
        </template>
    </CModal>

    <ConfirmModal
      v-if="form == 'confirm'"
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="confirmModalEvent"
      :confirm="confirmModalEvent"
      :desc="modalDesc"
      :noFooter="true"
    />
    <ListModal
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
    />
  </CRow>
</template>
<script>

import ListModal from '../components/listModal'
import ConfirmModal from '../components/confirmModal'

export default {
  name: 'OrderCheck',
  components:{ListModal, ConfirmModal},
  data() {
    return {
      lastItem: {},
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'orderCheckBlackList',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      blacklistFields: [],
      fields: [
        {key: 'url', label: 'Resim',_style: 'font-size:12px'},
        {key: 'description', label: 'Detay',_style: 'font-size:12px'},
        {key: 'skuCode', label: 'Ürün Kodu',_style: 'font-size:12px'},
        {key: 'barcode', label: 'Barkod',_style: 'font-size:12px'},
        {key: 'amount', label: 'Adet',_style: 'font-size:12px'},
        {key: 'check', label: 'Kontrol',_style: 'font-size:12px'},
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        }
      ],
      orderId: '',
      barcodeNo: '',
      giftCardNo: '',
      ordersData: {
        orders: [],
        count: 0
      },
      blacklistData: {
        blacklists: [],
        count: 0
      },
      checkedResponse: {checked: '', user: '', date: ''},
      showEttnImg: false,
      showWarningImg: false,
      showStreetImg: false,
      checkedMessage: '',
      barcodeList: [],
      selectedOrder: {},
      allCheckedMessage: '',
      giftCardMessage: '',
      clothBagMessage: '',
      giftCardChecked: false,
      allBarcodesChecked: false,
      errCode: 0,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    orderCheckList(){
      return this.$store.getters.orderCheckList;
    },
    loading(){
      return this.$store.getters.orderLoading;
    },
    shippingLoading(){
      return this.$store.getters.informShippingLoading;
    },
    invoiceId(){
      return this.$store.getters.orderCheckInvoiceIds[0].invoiceId
    }
  },
  methods: {
    rowClicked(item) {
      this.lastItem = item;
    },
    async openModalEvent(actionType, form, data, title, desc){
      if(form){
        this.openModal = !this.openModal
        this.actionType = actionType
        this.form = form
        this.modalProps = data
        this.modalTitle = title
        this.modalDesc = desc
      }else{
        this.openModal = false
      }
     
    },
    async listItem(){
      let params = {orderId: this.orderId}
      await this.$store.dispatch('orderCheck_list', params)
      if(this.$store.getters.orderCheckList.length < 1){
        document.getElementById("barcodeNumber").readOnly = true;
        document.getElementById("orderId").readOnly = true;
        document.getElementById("giftCardNumber").readOnly = true;
        document.getElementById("orderId").blur();
        this.openModalEvent('', 'confirm', null, 'Uyarı', 'Hatalı Sipariş No Girişi Yapıldı. Tekrar Deneyin!')
      }else {
        document.getElementById("barcodeNumber").focus();
        this.barcodeList = []
        this.$store.getters.orderCheckList.map(o => {
          this.giftCardMessage = o.giftPrintedFlag ? 'Bu siparişin hediye kartı var!' : 'Hediye Kartı Yok';
          this.showEttnImg = o.shippingAddress !== '192';
          this.showStreetImg = o.collectionName === 'Street';
          this.barcodeList.push(o.barcode);
          if(o.country === 192 && o.salePriceItemsTotal > process.env.VUE_APP_CLOTH_BAG_LIMIT) {
            this.clothBagMessage = "Bu siparişin bez çantası var";
          }
        })
        await this.$store.dispatch('orderCheck_isItemChecked', {invoiceId: this.$store.getters.orderCheckInvoiceIds[0].invoiceId })
        if(this.$store.getters.orderCheckItemChecked.checked === 'true'){
          document.getElementById("barcodeNumber").readOnly = true;
          document.getElementById("orderId").readOnly = true;
          document.getElementById("giftCardNumber").readOnly = true;
          document.getElementById("barcodeNumber").blur();
          let msg = this.orderId + ' No.lu sipariş ' + this.$store.getters.orderCheckItemChecked.date + ' tarihinde ' + 
          this.$store.getters.orderCheckItemChecked.user + ' tarafından kontrol edilmiştir.'
          this.openModalEvent('', 'confirm', null, 'Bilgilendirme', msg)
          this.checkedMessage = msg
        }
        if(this.$store.getters.orderCheckAllChecked){
          document.getElementById("barcodeNumber").readOnly = true;
          document.getElementById("orderId").readOnly = true;
          document.getElementById("giftCardNumber").readOnly = true;
          document.getElementById("barcodeNumber").blur();
          this.openModalEvent('', 'confirm', null, 'Uyarı', 'Bu siparişe ait faturalar daha önce kontrol edilmiştir.')
        }
        await this.blackListSearch()
      }
    },

    async blackListSearch() {
      await this.$store.dispatch('order_blackListSearch', {orderId: this.orderId})
      let errMsgs = ['Do not honor', 'Karta Elkoyunuz', 'CVC2/4CSC HATALI', 'Karta El Koyunuz', 'CHARGE BACK','SUSPICIOUS ORDER' ]
      for(let i = 0; i < this.$store.getters.blackList.length; i++){
        if(this.$store.getters.blackList[i].errMsg.filter(item => errMsgs.includes(item)).length > 0){
          this.showWarningImg = true;
          break;
        }
      }
      
    },
    async confirmModalEvent(){
      document.getElementById("barcodeNumber").readOnly = false;
      document.getElementById("orderId").readOnly = false;
      document.getElementById("giftCardNumber").readOnly = false;
      this.openModalEvent()
    },

    async approveOrder(item) {
      document.getElementById("barcodeNumber").focus();
      this.barcodeNo = '';
      let index = -1;
      this.lastItem = this.orderCheckList.find((e, i) => {
        if(e.barcode == item){
          index = i;
          return e;
        }
      })

      if (!this.barcodeList.includes(item) || !this.lastItem) {
        document.getElementById("barcodeNumber").readOnly = true;
        document.getElementById("orderId").readOnly = true;
        document.getElementById("giftCardNumber").readOnly = true;
        document.getElementById("barcodeNumber").blur();
        this.openModalEvent('', 'confirm', item, 'Uyarı', item +" barkod numaralı ürün listede bulunmamaktadır. Lütfen bir daha kontrol ediniz.")
      }else{
        if (this.lastItem.amount !== this.lastItem.check) {
          this.$store.commit('orderCheckListAddChecked',index)
        } else  {
          document.getElementById("barcodeNumber").readOnly = true;
          document.getElementById("orderId").readOnly = true;
          document.getElementById("giftCardNumber").readOnly = true;
          document.getElementById("barcodeNumber").blur();
          this.openModalEvent('', 'confirm', item, 'Hatalı Giriş!', this.lastItem.description +' ürünü 1 kereden fazla okutulmuştur. Lütfen ürün adetini yeniden kontrol ediniz')
        }
      
        this.allBarcodesChecked = this.orderCheckList.every(function (e) {
          return e.check === e.amount;
        });

        if (this.allBarcodesChecked) {
          if (this.lastItem.giftPrintedFlag) {
            document.getElementById("giftCardNumber").focus();
          }else {
            let params = {
              "invoiceId": this.invoiceId,
              "orderId": this.orderId.trim()
            };
            await this.$store.dispatch('orderCheck_itemChecked', params)
            if(this.$store.getters.orderStatus.success) {
              // DHL, UPS veya UPS(Domestic), Packup veya hepsiJet ise bildirme
              if(!['4', '6', '8', '10', '11'].includes(this.lastItem.shippingCompany)) {
                await this.$store.dispatch('order_informShippingCompany', {orderIds: this.orderId.trim(), forceInform: false })

                let toast;

                if(this.$store.getters.orderStatus.success) {
                  toast = {
                    msg: 'Kargo bilgilendirmesi başarıyla gerçekleşmiştir.',
                    header: 'Başarılı',
                    color: 'success'
                  }
                }else {
                  toast = {
                    msg: 'Kargo bilgilendirme aşamasında bir hata oluştu' + this.$store.getters.orderStatus.errMsg,
                    header: 'Bilgilendirme Hata',
                    color: 'danger'
                  }
                }

                this.$store.commit('showToast', toast)
                document.getElementById("orderId").focus();

              }
            }
            this.allCheckedMessage = 'Kontrol İşlemi Tamamlanmıştır.';
            this.orderId = '';
            this.checkedMessage = '';
            this.giftCardMessage = '';
            document.getElementById("orderId").focus();
          }
        }
      }
      
    },
    async approveGiftCard(item) {
      this.giftCardNo = '';
      if (item !== this.orderId) {
          document.getElementById("barcodeNumber").readOnly = true;
          document.getElementById("orderId").readOnly = true;
          document.getElementById("giftCardNumber").readOnly = true;
          document.getElementById("giftCardNumber").blur();
          this.openModalEvent('', 'confirm', item, 'Hatalı Giriş!', item + "  No.'lu hediye kartı doğrulanmadı. Lütfen bir daha kontrol ediniz.")
          return;
      }
      await this.orderCheckList.forEach(o => {
        if (o.giftPrintedFlag) {
          this.giftCardChecked = true;
          this.giftCardMessage = 'Hediye kartı kontrol işlemi tamamlanmıştır.';
        }
      });

      if (this.allBarcodesChecked) {
        this.allCheckedMessage = 'Kontrol İşlemi Tamamlanmıştır.';
        document.getElementById("orderId").focus();

        let params = {
          "invoiceId": this.invoiceId,
          "orderId": this.orderId.trim()
        };
        await this.$store.dispatch('orderCheck_itemChecked', params)
        if(this.$store.getters.orderStatus.success) {
          // DHL, UPS veya UPS(Domestic), Packup veya hepsiJet ise bildirme
          if(!['4', '6', '8', '10', '11'].includes(this.lastItem.shippingCompany)) {
            await this.$store.dispatch('order_informShippingCompany', {orderIds: this.orderId.trim(), forceInform: false })
            let toast;

            if(this.$store.getters.orderStatus.success) {
              toast = {
                msg: 'Kargo bilgilendirmesi başarıyla gerçekleşmiştir.',
                header: 'Başarılı',
                color: 'success'
              }
            }else {
              toast = {
                msg: 'Kargo bilgilendirme aşamasında bir hata oluştu' + this.$store.getters.orderStatus.errMsg,
                header: 'Bilgilendirme Hata',
                color: 'danger'
              }
            }

            this.$store.commit('showToast', toast)

          }
        }
        this.checkedMessage = '';
        this.orderId ="";
        this.barcodeNo = '';
      }
    },
  },
}
</script>
<style scoped>
.order{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.order > div{
  min-width: 40%;
}
</style>
